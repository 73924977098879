import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchBrandImages,
  fetchPhotoWh,
  fetchImageAudit,
  fetchM2Brands,
  updateSuboptimalImages,
  processTransfer,
} from '../actions';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Dropdown from './Dropdown';

import teal from '@material-ui/core/colors/teal'
import blue from '@material-ui/core/colors/blue'
import lightBlue from '@material-ui/core/colors/lightBlue'
import blueGrey from '@material-ui/core/colors/blueGrey'

const colorPriority = (priority) => {
  switch (priority) {
    case 'A':
      return blue['100']
    case 'B':
      return lightBlue['100']
    case 'C':
      return teal['100']
    default:
      return blueGrey['100']
  }
}

class Images extends Component {
  state = {
    tabs: 0,
    imageStatuses: [
      'Unassessed',
      'Suboptimal - Check Used',
      'Suboptimal - Check MFR',
      'Suboptimal - Needs Photos',
      'Updated / Suitable'
    ],
    loading: true,
    imagesLoading: true,
    brandsLoading: true,
    selectedBrand: '',
    brandsTable: [],
    usedTable: [],
    mfrTable: [],
    items: [],
    imageOmits: [],
    modalOpen: false,
    modalImages: [],
    modalCurrent: 0,
    modalUsed: false,
    modalProduct: '',
    modalSubmission: false,
    modalResult: '',
    submitting: false,
    transferred: false,
    // for photowh transfers
    type: 'S',
    toId: 12,
    fromId: 2,
    note: 'photos',
    name: this.props.auth.name,
    response: ''
  };

  componentDidMount() {
    // fetches list of NEW skus in photo warehouse
    this.props.fetchPhotoWh().then((_resp) => {
      this.setState({ imagesLoading: false });
    });

    this.props.fetchImageAudit().then((_resp) => {
      this.setState({ imagesLoading: false });
    });

    // fetches out list of magento brands
    this.props.fetchM2Brands().then((_resp) => {
      this.setState({ brandsLoading: false });
    }).then(() => {
      this.setState({
        loading: false,
      })
    })

    window.addEventListener('keydown', this.detectEscape);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.detectEscape);
  }

  detectEscape = (e) => {
    if (e.key === 'Escape') {
      if (this.state.modalOpen && !this.state.modalSubmission) {
        this.closeModal();
      }
    }
  }

  // used for tabs of table views
  tabber = (_event, value) => {
    this.setState({ 
      tabs: value,
      selectedBrand: this.state.selectedBrand,
    });
  };

  // re-usable state re-set function
  closeModal = () => {
    this.setState({ 
      modalOpen: false,
      modalCurrent: 0,
      modalSubmission: false,
      modalResult: '',
      submitting: false,
      transferred: false,
    });
  };

  // fetch photos + data for products of brand
  handleSelectBrand = (_event, newValue) => {
    if (newValue) {
      this.setState({ selectedBrand: newValue.title });
      this.mapFetchedBrands(newValue.title);
    } else {
      this.setState({ selectedBrand: '' })
    }
  }

  // map the data into different tables for independent state management
  mapFetchedBrands = (newValue) => {
    this.props.fetchBrandImages({name: newValue}).then(() => {
      this.setState({ 
        brandsTable: this.props.imageAudit.brand.filter((item) => {
          return item.image_status === 'Unassessed';
        }),
        usedTable: this.props.imageAudit.brand.filter((item) => {
          return item.used && item.image_status === 'Suboptimal - Check Used';
        }).map((item) => {
          return {
            ...item,
            resolve: '',
          }
        }),
        mfrTable: this.props.imageAudit.brand.filter((item) => {
          return item.image_status === 'Suboptimal - Check MFR';
        }).map((item) => {
          return {
            ...item,
            shoot: 'No',
          }
        })
      });
    });
  }

  // handlers for nudging statuses along the flow
  //
  // initial assessment: status select dropdown
  handleStatusSelect = (e, r) => {
    const index = r._index;
    r.image_status = e.target.value;
    const table = [...this.state.brandsTable];
    const newEntry = {...table[index]};
    newEntry.image_status = e.target.value;
    table[index] = newEntry;
    this.setState({ brandsTable: table });
  }

  // used check: set 'resolve' status 
  //    - push a product in this view into MFR status
  //    - void the current entry if there is one
  //        - MFR
  //        - used sku from child table
  handleResolve = (r) => {
    const index = r.index;
    const table = [...this.state.usedTable];
    const newEntry = {...table[index]};
    newEntry.resolve = r.original.resolve ? '' : 'MFR';
    table[index] = newEntry;
    this.setState({ usedTable: table });
  }

  // used check: push used to sku into parent's 'resolve' cell
  handleParentResolve = (r) => {
    const index = r.parent.index;
    const table = [...this.state.usedTable];
    const newEntry = {...table[index]};
    newEntry.resolve = r.sku;
    table[index] = newEntry;
    this.setState({ usedTable: table })
  }

  // mfr check: set whether or not we should shoot pics for this product
  handleShoot = (r) => {
    const index = r._index;
    const table = [...this.state.mfrTable];
    const newEntry = {...table[index]};
    newEntry.shoot = newEntry.shoot === 'No' ? 'Yes' : 'No';
    table[index] = newEntry;
    this.setState({ mfrTable: table });
  }

  // compile and prepare modified data for imports 
  confirmSync = () => {
    this.setState({
      modalSubmission: true,
    });

    let table;
    // choose table based on current tab
    switch (this.state.tabs) {
      case 1:
        table = this.state.usedTable;
        break;
      case 2:
        table = this.state.mfrTable;
        break;
      default:
        table = this.state.brandsTable;
        break;
    }

    // big set of checks for data
    const updated = table.filter((product) => {
      // mfr check: ignore products where shoot is No
      if (product.shoot) {
        if (product.shoot === 'No') {
          return false;
        } else {
          return true;
        }
      }
      // check for modified image status OR if a product has resolve field
      return product.image_status !== product.image_status_og || product.resolve;
    }).map((product) => {
      let status = product.image_status;
      // on the first page, we're just generall assessing if an image is suboptimal
      // since we're pulling ALL the data for this sheet, we can totally skip the 
      // stage of checking used gear *if we have never had that product used*
      if (status === 'Suboptimal') {
        status = product.used ? 'Suboptimal - Check Used' : 'Suboptimal - Check MFR';
      }
      // on the second page, we're checking the existence of used photos for the product
      // there are a few different outcomes here:
      //    - we can replace a product's images with viable used pictures
      //        - this pushes them directly to magento
      //        - this also sets image_status to Updated / Suitable
      //    - we can move a product along to Check MFR if no viable replacements are available
      //    - products with an empty field will be skipped for now, but ideally everything should
      //      have an outcome
      if (product.resolve) {
        if (product.resolve === 'MFR') {
          return {
            sku: product.sku,
            brand: product.brand,
            name: product.name,
            image_status: 'Suboptimal - Check MFR',
            bpId: product.bpId,
            bpName: product.bpName,
            qty: product.qty,
          }
        } else {
          // get the used images from subtable
          const resolveLookup = product.resolve;
          const replacement = product.used.usedSkus.find((used) => used.sku === resolveLookup);
          const images = replacement.images.filter((image) => {
            return !this.state.imageOmits.includes(image)
          });
          return {
            sku: product.sku,
            brand: product.brand,
            name: product.name,
            image_status: `Uploading ${product.resolve} images`,
            images: images,
            bpId: product.bpId,
            bpName: product.bpName,
            dropboxFolder: replacement.dropboxFolder,
            qty: product.qty,
          }
        }
      }
      // on the MFR page, we can leave a product in this stage or decide if we wanna shoot our own pics
      if (product.shoot) {
        status = product.shoot === 'Yes' ? 'Suboptimal - Needs Photos' : 'Suboptimal - Check MFR';
      }
      return {
        sku: product.sku,
        brand: product.brand,
        name: product.name,
        image_status: status,
        bpId: product.bpId,
        bpName: product.bpName,
        qty: product.qty,
      }
    });
    this.setState({ 
      items: updated,
      modalOpen: true,
    });
  }

  // push the updated products to magento
  syncSuboptimalImages = (type) => {
    this.setState({ 
      submitting : true,
    })
    this.props.updateSuboptimalImages(this.state.items).then((_resp) => {
      // optional transfer on the MFR stage
      if (type === 'transfer') {
        // remove OOS items from list, and transfer just 1 of them
        const bpMap = this.state.items.filter((item) => {
          return item.qty > 0
        }).map((item) => {
          return {
            sku: item.sku,
            bpId: item.bpId,
            qty: 1,
          }
        });
        this.setState({ items: bpMap });
        this.props.processTransfer(this.state).then(() => {

          this.setState({
            submitting: false,
            transferred: true,
            response: this.props.transfer.response,
          })
        })
      } else {
        this.setState({ 
          submitting: false,
        });
        this.closeModal()
      }
      // fetch again to reset table
      this.mapFetchedBrands(this.state.selectedBrand);
    });
    
  }

  // ImageModal component for viewing blown up images on the page
  ImageModal = () => {
    const src = this.state.modalImages[this.state.modalCurrent]
    const omit = this.state.imageOmits.some((image) => image === src) ? 0.25 : 1;
    return (
      <Paper
        style={{
          position: 'absolute',
          top: `20%`,
          left: `10%`,
          width: '80%',
          aspectRatio: 1 / 2,
          outline: 0,
          padding: 25,
        }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img 
            src={src}
              style={{ 
                alignSelf: 'center',
                opacity: omit,
              }}
              width={'50%'}
          />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {this.state.modalImages.length > 1 && (
            <Button onClick={() => this.imageIncrement(-1)}>
              Prev
            </Button>
          )}
          {this.state.modalUsed && (
            <Button onClick={() => this.omitImage()}>
              Omit
            </Button>
          )}
          {this.state.modalImages.length > 1 && (
            <Button onClick={() => this.imageIncrement(1)}>
              Next
            </Button>
          )}
        </div>
        <div style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Button
            style={{ marginTop: 15 }}
            onClick={this.closeModal}
            variant='contained'
            color='secondary'>
              close
          </Button>
        </div>
      </Paper>
    )
  }

  // navigate thru images in modal
  imageIncrement = (e) => {
    let current = this.state.modalCurrent;
    let length = this.state.modalImages.length-1;
    current += e;
    current = current < 0 ? length : current;
    current = current > length ? 0 : current;
    this.setState({
      modalCurrent: current
    })
  }

  // omit (used) image from import / replacement
  // applicable to power supplies, accessories, etc
  omitImage = () => {
    const { imageOmits, modalImages, modalCurrent } = this.state;
    let omits = [...imageOmits];
    const current = modalImages[modalCurrent];
    const index = omits.indexOf(current);
    if (index > -1) {
      omits.splice(index,1);
    } else {
      omits.push(modalImages[modalCurrent])
    }
    this.setState({
      imageOmits: omits,
    })
  }

  // a whole bunch of declarations for re-usable stylings and column types
  //
  // cell style used on almost everything
  defaultCellStyle = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  }

  // array of columns used on most tables
  sharedColumns = [
    { 
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: this.defaultCellStyle,
    },
    {
      Header: 'Name',
      accessor: 'name', 
      width: 400,
      style: this.defaultCellStyle,
    }
  ];

  // column for pm index
  indexColumn = { 
    Header: 'Index',
    accessor: 'pmIndex',
    width: 50,
    style: this.defaultCellStyle,
  }

  // column for images
  imageColumn = {
    Header: 'Images',
    accessor: 'images',
    style: {
      display: 'flex',
    },
    Cell: tableProps => (this.imageBlock(tableProps))
  };

  // block of button-ified images for modal carousel thingie
  imageBlock = (props) => {
    let imgs = props.row.images;
    if (props.original.resolve) {
      if (props.original.resolve !== 'MFR') {
        const used = props.original.used.usedSkus.find((images) => {return images.sku === props.original.resolve});
        imgs = used.images;
      }
    }
    const source = props.row.sku.charAt(0) === 'U';
    // preventing the unique key prop warning
    // we're not modifying the array so i think this is ok?
    const imgBlock = imgs.map((img, i) => {
      const omit = this.state.imageOmits.some((image) => image === img) ? 0.25 : 1;
      const keyName = `product-${props.row.pmIndex}-${imgs[i]}`
      return (
        <Button
          key={keyName}
          onClick={() => {
            this.setState({ 
              modalOpen: true,
              modalImages: imgs,
              modalUsed: source,
              modalProduct: props.row.sku,
              modalCurrent: i,
            })
          }}
        >
          <img src={img} width={200} style={{opacity: omit}}/>
        </Button>
      )
    });
    return (<div>{imgBlock}</div>)
  }

  BrandDropdownBar = () => {
    // Create Brand Options based on table data
    let brandOptions;
    if (this.state.loading) {
      brandOptions = [{ value: 0, title: "...Loading" }]
    }
    else {
      const activeBrands = this.props.m2Brand.filter((brand) => {
        return brand.numProd > 0;
      })
      brandOptions = activeBrands.map((brand) => {
        return { value: brand.value, title: brand.label, count: brand.numProd }
      })
    }

    return (
      <Grid container spacing={10} alignItems='center' style={{ padding: 10 }}>
        <Grid item xs={4}>
          <Dropdown
            id={'brand-select'}
            label={this.state.selectedBrand}
            entries={brandOptions}
            onChangeHandler={this.handleSelectBrand}
          />
        </Grid>
        <Grid>
          <Button
            variant='contained'
            label='Sync Suboptimal Images'
            onClick={() => this.confirmSync()}
            style={{ marginRight: 20 }}
          >
            Sync Suboptimal Images
          </Button>
        </Grid>
      </Grid>
    )
  }

  // columns for brand table
  brandImageColumns = [
    { 
      Header: 'Index',
      accessor: 'pmIndex',
      width: 50,
      style: this.defaultCellStyle,
    },
    ...this.sharedColumns,
    { 
      Header: 'bpStatus',
      accessor: 'bpStatus',
      width: 150,
      style: this.defaultCellStyle,
    },
    { 
      Header: 'Used',
      accessor: 'original',
      width: 50,
      style: this.defaultCellStyle,
      Cell: (row) => {
        if (row.original.used) {
          return (
            <>
              USED
            </>
          )
        } else {
          return (<></>);
        }
      }
    },
    {
      Header: 'Status',
      accessor: 'image_status',
      width: 225,
      style: this.defaultCellStyle,
      Cell: (tableProps) => {
        const theme = createTheme({
          overrides: {
            MuiMenuItem: {
              root: {
                "&:hover": {
                  'text-decoration': 'underline',
                },
                "&$selected": {
                  'font-weight': 'bold',
                }
              }
            }
          }
        })
        const imageStatuses = ['Unassessed', 'Suboptimal', 'Updated / Suitable']
        const options = imageStatuses.map((imageStatus, index) => {
          return (
            <MenuItem
              key={index}
              value={imageStatus}
              style={{ fontSize: 14, backgroundColor: 'white' }}
            >
              {imageStatus}
            </MenuItem>
          )
        })
        return (
          <ThemeProvider theme={theme}>
            <FormControl variant='outlined' size='small' fullWidth>
              <Select
                value={tableProps.value}
                style={{ fontSize: 14, backgroundColor: 'white' }}
                onChange={(event) => this.handleStatusSelect(event, tableProps.row)}
              >
                {options}
              </Select>
            </FormControl>
          </ThemeProvider>
        )
      },
    },
    // lil hack to store the original value,
    // used for row coloring to indicate a changed value
    {
      Header: 'Status_og',
      accessor: 'image_status_og',
      show: false,
    },
    this.imageColumn,
    {
      Header: 'Visibility',
      accessor: 'visibility',
      show: false,
    }
  ];

  // columns for used table
  usedLookupColumns = [
    this.indexColumn,
    ...this.sharedColumns,
    {
      expander: true,
      Header: 'Used',
      accessor: "_original",
      width: 80,
      style: this.defaultCellStyle,
      Expander: ({ isExpanded, row }) => {
        if (row._original.used) {
          return (
            <div style={{ display: 'inline-flex', paddingTop: 6 }}>
              ({row._original.used.usedSkus.length})
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          )
        } else {
          return null;
        }
      }
    },
    {
      Header: 'Resolve',
      width: 150,
      style: {
        ...this.defaultCellStyle,
        justifyContent: 'center',
      },
      Cell: (row) => {
        return (
          <Button
            style={{
              display: 'block', margin: '10px', height: '60px', border: '2px solid black'
            }}
            onClick={()=> this.handleResolve(row)}
          >
            {row.original.resolve}
          </Button>
        )
      }
    },
    this.imageColumn,
  ]

  // columns for used table subtable
  subComponentColumns = [
    this.sharedColumns[0], // sku column only
    {
      Header: 'Replace?',
      width: 125,
      style: this.defaultCellStyle,
      Cell: (row) => {
        const innerText = row.original.parent.original.resolve === row.original.sku ? 'Yes' : 'No';
        return(
        <Button
          style={{
            display: 'block', margin: '10px', height: '60px', border: '2px solid black'
          }}
          onClick={() => {
            this.handleParentResolve(row.original)
          }}
        >
          {innerText}
        </Button>)}
    },
    this.imageColumn,
  ];

  // columns for mfr table
  mfrColumns = [
    this.indexColumn,
    ...this.sharedColumns,
    {
      Header: 'Shoot?',
      accessor: 'shoot',
      width: 125,
      style: this.defaultCellStyle,
      Cell: (tableProps) => {
        return(
        <Button
          onClick={() => {
            this.handleShoot(tableProps.row)
          }}
        >
          {tableProps.row.shoot}
        </Button>)}
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      width: 125,
      style: this.defaultCellStyle
    },
    this.imageColumn,
  ]

  // columns for photowh table
  auditColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: this.defaultCellStyle
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 125,
      style: this.defaultCellStyle
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: this.defaultCellStyle
    },
    {
      Header: 'Priority',
      accessor: 'priority',
      width: 125,
      style: this.defaultCellStyle
    },
    {
      Header: 'Status',
      accessor: 'image_status',
      style: this.defaultCellStyle
    },
    {
      Header: 'Has Used?',
      accessor: 'hasUsed',
      width: 125,
      style: this.defaultCellStyle
    }
  ];

  // columns for photowh table
  photoWhColumns = [
    ...this.sharedColumns,
    {
      Header: 'Status',
      accessor: 'image_status',
      width: 125,
      style: this.defaultCellStyle
    },
    this.imageColumn,
  ];

  // columns for submission table
  submitColumns = [
    ...this.sharedColumns,
    {
      Header: 'Status',
      accessor: 'image_status',
      style: this.defaultCellStyle
    },
  ];

  // columns for transferring products
  transferColumns = [
    { 
      Header: 'SKU',
      accessor: 'sku',
      width: 125,
      style: this.defaultCellStyle,
    },
    {
      Header: 'Id',
      accessor: 'bpId',
      style: this.defaultCellStyle,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      width: 125,
      style: this.defaultCellStyle
    }
  ];

  // big big conditional formatting junk
  rowFormat = (rowInfo) => {
    // this gets funny with nested ifs / logic checks, so doing them separately
    // change row color if changing shoot
    if (
      rowInfo && rowInfo.original.bpStatus === 'DISCONTINUED'
    )
      return {
        style: {
          background: rowInfo.index % 2 ? '#895555' : '#EF9999' 
        }
      };
    if (
      rowInfo && rowInfo.row.shoot === 'Yes'
    )
      return {
        style: {
          background: rowInfo.index % 2 ? '#454545' : '#676767' 
        }
      };
    
    // change row color if changing resolve
    if (
      rowInfo && rowInfo.original.resolve
    )
      return {
        style: {
          background: rowInfo.index % 2 ? '#454545' : '#676767' 
        }
      };
    // change row color if changing suboptimal attr
    if (
      rowInfo && rowInfo.row.image_status !== rowInfo.row.image_status_og
    )
      return { 
        style: { 
          background: rowInfo.index % 2 ? '#454545' : '#676767' 
        } 
      };
    // change row color if config child
    else if (
      rowInfo &&
      rowInfo.original.visibility == 'Not Visible Individually'
    )
      return { 
        style: { 
          background: rowInfo.index % 2 ? '#ABABDE' : '#BCBCEF'
        }
      };
    // change row color if config parent
    else if (
      rowInfo &&
      rowInfo.original.sku.charAt(0) == 'C'
    )
      return { 
        style: { 
          background: rowInfo.index % 2 ? '#9A9AEF' : '#8989DE'
        } 
      };
    else return {};
  }

  // finally, the render function
  render() {
    const { tabs, items, brandsTable, usedTable, mfrTable } = this.state;

    return (
      <PaperPage maxWidth='100%'>
        <Modal open={this.state.modalOpen && !this.state.modalSubmission}>
            <this.ImageModal />
          </Modal>
          <Modal open={this.state.modalOpen && this.state.modalSubmission}>
            <Paper
              style={{
                position: 'absolute',
                top: `20%`,
                left: `10%`,
                width: '80%',
                aspectRatio: 1 / 2,
                outline: 0,
                padding: 25,
            }}>
              <TopBar pageName='Confirm Submission'>
                <TopBarRow justify='flex-end'>
                  { this.state.transferred && (
                    <>
                      Products transferred  
                    </>
                  )}
                  { !this.state.transferred && (
                    <>
                      This is an import—be sure to alert other PM team members before clicking submit  
                    </>
                  )}
                  <Button
                    style={{ marginLeft: 20 }}
                    disabled={this.state.submitting || this.state.transferred} 
                    onClick={() => this.syncSuboptimalImages('sync')}
                    variant='contained'
                    color='secondary'>
                      Submit
                    </Button>
                    {tabs === 2 && (
                      <Button
                        style={{ marginLeft: 20 }}
                        disabled={this.state.submitting || this.state.transferred} 
                        onClick={() => this.syncSuboptimalImages('transfer')}
                        variant='contained'
                        color='secondary'>
                          Submit + Transfer
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      disabled={this.state.submitting} 
                      onClick={() => this.closeModal()}
                      label='Close Submission Modal'
                      style={{ marginLeft: 20 }}>
                      Go Back
                    </Button>
                </TopBarRow>
              </TopBar>
              <Grid>
                <ReactTable
                  data={items}
                  columns={this.state.transferred ? this.transferColumns : this.submitColumns}
                  style={{ textAlign: 'left' }}
                  defaultFilterMethod={(filter, row) => {
                    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
                    return (
                      rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                    );
                  }}
                  defaultSorted={[
                    { id: 'sku', desc: false },
                  ]}
                  loading={this.state.imagesLoading}
                  loadingText='Loading...'
                  showPagination
                  minRows={10}
                  pageSize={100}
                  className='-striped -highlight'
                />
              </Grid>
              {this.state.submitting && (
                <LinearProgress
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                  color='secondary'
                />
              )}
            </Paper>
        </Modal>

        {/* Header Bar */}
        <TopBar pageName='Images'>
          <TopBarRow justify='flex-end'>
            <Button variant="contained" href={'/api/images/audit/csv'} label="Export Errors" style={
              { 
                width: 200,
                marginLeft: 20,
                height: 30, 
                marginTop: 10
              }
            }>
              Export Image Audit
            </Button>
          </TopBarRow>
        </TopBar>

        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={tabs} onChange={this.tabber}>
            <Tab data-tip data-for='assessment' label='1. Unassessed' />
            <Tab data-tip data-for='used-replacement' label='2. Used Replacement' />
            <Tab data-tip data-for='check-mfr' label='3. Check MFR' />
            <Tab data-tip data-for='audit' label='Audit' />
            <Tab data-tip data-for='photo-wh' label='Photo WH' />
          </Tabs>
        </AppBar>

        <ReactTooltip id='assessment' place='top' type='dark' effect='solid'>
          Assess status for product images by brand
        </ReactTooltip>
        <ReactTooltip id='used-replacement' place='top' type='dark' effect='solid'>
          Replace new listing images with used product pics
        </ReactTooltip>
        <ReactTooltip id='check-mfr' place='top' type='dark' effect='solid'>
          Does the Manufacturer have good pics?
        </ReactTooltip>
        <ReactTooltip id='audit' place='top' type='dark' effect='solid'>
          View all products in mid-stages of audit
        </ReactTooltip>
        <ReactTooltip id='photo-wh' place='top' type='dark' effect='solid'>
          Non-used skus in photo warehouse
        </ReactTooltip>

        {/* brands audit */}
        {tabs === 0 && (
          <Grid >
            <this.BrandDropdownBar />
            <Grid>
              <ReactTable
                data={brandsTable}
                columns={this.brandImageColumns}
                style={{ textAlign: 'left' }}
                filterable
                defaultFilterMethod={(filter, row) => {
                  let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
                  return (
                    rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                  );
                }}
                defaultSorted={[
                  { id: 'pmIndex', desc: false },
                ]}
                loading={this.state.imagesLoading}
                loadingText='Loading...'
                showPagination
                minRows={10}
                pageSize={100}
                className='-striped -highlight'
                getTrProps={(_state, rowInfo, _column) => {
                  return this.rowFormat(rowInfo);
                }}
              />
            </Grid>
          </Grid>
        )}
        {/* used image lookup */}
        {tabs === 1 && (
          <Grid>
            <this.BrandDropdownBar />
            <Grid>
              <ReactTable
                data={usedTable}
                columns={this.usedLookupColumns}
                style={{ textAlign: 'left' }}
                filterable
                defaultFilterMethod={(filter, row) => {
                  let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
                  return (
                    rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                  );
                }}
                defaultSorted={[
                  { id: 'pmIndex', desc: false },
                ]}
                loading={this.state.imagesLoading}
                loadingText='Loading...'
                showPagination
                minRows={10}
                pageSize={100}
                className='-striped -highlight'
                getTrProps={(_state, rowInfo, _column) => {
                  return this.rowFormat(rowInfo);
                }}
                SubComponent={(row) => {
                  if (row.original.used) {
                    const data = row.original.used.usedSkus.map((item) => {
                      return {
                        ...item,
                        parent: row,
                      }
                    });
                    return (
                      <ReactTable
                        data={[...data]}
                        columns={this.subComponentColumns}
                        className='-striped -highlight'
                        showPagination
                        minRows={data.length}
                        pageSize={data.length}
                        getTrProps={(_state, rowInfo, _column) => {
                          return this.rowFormat(rowInfo);
                        }}
                      />
                    )
                  } else {
                    return null;
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        {/* MFR assessment */}
        {tabs === 2 && (
          <Grid>
            <this.BrandDropdownBar />
            <Grid>
              <ReactTable
                data={mfrTable}
                columns={this.mfrColumns}
                style={{ textAlign: 'left' }}
                filterable
                defaultFilterMethod={(filter, row) => {
                  let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
                  return (
                    rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                  );
                }}
                defaultSorted={[
                  { id: 'pmIndex', desc: false },
                ]}
                loading={this.state.imagesLoading}
                loadingText='Loading...'
                showPagination
                minRows={10}
                pageSize={100}
                className='-striped -highlight'
                getTrProps={(_state, rowInfo, _column) => {
                  return this.rowFormat(rowInfo);
                }}
              />
            </Grid>
          </Grid>
        )}
        {/* status audit */}
        {tabs === 3 && (
          <ReactTable
            data={this.props.imageAudit.imageaudit}
            columns={this.auditColumns}
            style={{ textAlign: 'left' }}
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            defaultSorted={[
              { id: 'priority', desc: false },
            ]}
            loading={this.state.imagesLoading}
            loadingText='Loading...'
            showPagination
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo)
                return {
                  style: { background: colorPriority(rowInfo.row.priority) },
                }
              else return {}
            }}
          />
        )}
        {/* photo warehouse */}
        {tabs === 4 && (
          <ReactTable
            data={this.props.imageAudit.photowh}
            columns={this.photoWhColumns}
            style={{ textAlign: 'left' }}
            filterable
            defaultFilterMethod={(filter, row) => {
              let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
              return (
                rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
              );
            }}
            defaultSorted={[
              { id: 'sku', desc: false },
              { id: 'brand', desc: false },
            ]}
            loading={this.state.imagesLoading}
            loadingText='Loading...'
            showPagination
            minRows={10}
            pageSize={100}
            className='-striped -highlight'
          />
        )}
      </PaperPage>
    );
  }
}

function mapStateToProps({
  imageAudit,
  m2Brand,
  auth,
  transfer
}) {
  return {
    imageAudit,
    m2Brand,
    auth,
    transfer
  };
}
const mapDispatchToProps = {
  fetchBrandImages,
  fetchPhotoWh,
  fetchImageAudit,
  fetchM2Brands,
  updateSuboptimalImages,
  processTransfer,
};
export default connect(mapStateToProps, mapDispatchToProps)(Images);
